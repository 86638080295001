
export default {
    // components: {
    //   Swiper
    // },
    props: [],
    name: 'about',
    data() {
        return {
            activeIndex: '1',
            activeIndex2: '1',
            offList: [
                { img: require("../../assets/企查查.png") },
                { img: require("../../assets/富滇.png") },
                { img: require("../../assets/恒丰.png") },
                { img: require("../../assets/民生.png") },
                { img: require("../../assets/华夏.png") },
                { img: require("../../assets/信达.png") },
                { img: require("../../assets/山西.png") },
                { img: require("../../assets/阿里云.png") },
                { img: require("../../assets/浙商.png") },
                { img: require("../../assets/重庆.png") },
                { img: require("../../assets/重庆农商.png") },
                { img: require("../../assets/中信.png") },
                { img: require("../../assets/兴业.png") },
                { img: require("../../assets/云南.png") },

            ],
            offList2: [
                { img: require("../../assets/阿里云.png") },
                { img: require("../../assets/浙商.png") },
                { img: require("../../assets/重庆.png") },
                { img: require("../../assets/重庆农商.png") },
                { img: require("../../assets/中信.png") },
                { img: require("../../assets/兴业.png") },
                { img: require("../../assets/云南.png") },
                { img: require("../../assets/企查查.png") },
                { img: require("../../assets/富滇.png") },
                { img: require("../../assets/恒丰.png") },
                { img: require("../../assets/民生.png") },
                { img: require("../../assets/华夏.png") },
                { img: require("../../assets/信达.png") },
                { img: require("../../assets/山西.png") },
            ],

        }
    },
    created() {
    },
    mounted() {

    },
    methods: {
        handleSelect(key, keyPath) {
            console.log(key, keyPath);
        }
    }
}
